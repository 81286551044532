import { Box, Grid, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useAsyncData } from "@torqit/torq-tools-react";
import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { toast } from "react-toastify";
import { Countries } from "../../addresses/Countries";
import { MarathonButton } from "../../common/components/MarathonButton";
import { makeAsyncCall } from "../../common/hooks/makeAsyncCall";
import { fieldName } from "../../forms/utils/fieldName";
import { fieldNames } from "../../forms/utils/fieldNames";
import { getShippingInformation } from "../api/getShippingInformation";
import { saveShippingData } from "../api/saveShippingData";
import { CheckoutSteps } from "../Checkout";
import { AdditionalShippingInformation } from "../types/AdditionalShippingInformation";
import { type Address, ShippingInformationSave } from "../types/Checkout";
import { ShippingOptions } from "../types/ShippingOptions";
import { AdditionalInformationFields } from "./AdditionalInformationFields";
import { SelectShippingOptionField } from "./SelectShippingOptionField";
import { ShippingOptionComponent } from "./ShippingOptionComponent";
import type { Cart } from "../types/Cart";

export type ShippingProps = {
  cart?: Cart | null;
  setCheckoutStep(checkoutStep: CheckoutSteps): void;
};

export const Shipping: React.FC<ShippingProps> = ({ setCheckoutStep, cart }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const { data: shippingInformation, isLoading: isShippingInformationLoading } = useAsyncData(
    getShippingInformation,
    []
  );

  const { callApi, isLoading } = makeAsyncCall({
    apiMethod: saveShippingData,
    onSuccess: () => setCheckoutStep(CheckoutSteps.Payment),
    onError: (message) => toast.error(message ?? "Error saving shipping data."),
  });

  const initialNewAddress: Address = useMemo(() => {
    if (shippingInformation?.shippingMethod === ShippingOptions.New && shippingInformation?.defaultShipToId) {
      const shipTo = shippingInformation.shipTos?.find(
        (shipTo) => shipTo.shipToId === shippingInformation.defaultShipToId
      );
      return {
        addressName: shipTo?.name,
        customerName: shipTo?.customerName,
        addressLine1: shipTo?.address1,
        addressLine2: shipTo?.address2,
        addressCity: shipTo?.city,
        addressProvince: shipTo?.province,
        addressPostalCode: shipTo?.postalCode,
        addressCountry: shipTo?.country,
        shipToId: shipTo?.shipToId,
        addressDefault: false,
      };
    } else {
      return {
        addressDefault: false,
        addressCountry: Countries.CANADA.toUpperCase(),
      };
    }
  }, [shippingInformation]);

  return (
    <>
      <h2>Delivery Options</h2>
      <h3>How would you like to receive your order?</h3>
      {isShippingInformationLoading ? (
        <Grid container justifyContent={"justify-content"} gap={2} direction={isMobile ? "column" : "row"}>
          <Skeleton height={150} width={315} />
          <Skeleton height={150} width={315} />
          <Skeleton height={150} width={315} />
          <br />
          <Skeleton height={650} sx={{ transformOrigin: "top", width: "100%" }} />
        </Grid>
      ) : (
        <Form<ShippingInformationSave>
          keepDirtyOnReinitialize
          mutators={{
            setEmail: ([name, value], state, { changeValue }) => {
              if (value) {
                changeValue(state, name, () => value);
              }
            },
          }}
          initialValues={{
            shippingMethod:
              shippingInformation?.shippingMethod &&
              Object.values(ShippingOptions).includes(shippingInformation.shippingMethod)
                ? shippingInformation?.shippingMethod
                : ShippingOptions.DeliverToMe,
            customerPoNumber: shippingInformation?.poNumber,
            orderComment: shippingInformation?.orderComment,
            secondaryEmails: shippingInformation?.secondaryEmails,
            saveSecondaryEmails: shippingInformation?.saveSecondaryEmails,
            shipTo: shippingInformation?.defaultShipToId ?? 0,
            pickupDate: shippingInformation?.initialPickupDate,
            pickupTime: shippingInformation?.selectedPickupTime,
            shouldReceiveEmailNotifications: shippingInformation?.shouldReceiveEmailNotifications,
            emailNotificationValue: shippingInformation?.emailNotificationValue,
            shouldReceiveTextNotifications: shippingInformation?.shouldReceiveTextNotifications,
            textNotificationValue: shippingInformation?.textNotificationValue,
            rememberNotificationDetails: shippingInformation?.rememberNotificationDetails,
            newAddress: initialNewAddress,
          }}
          onSubmit={callApi}
          render={({ values, handleSubmit }) => (
            <>
              <SelectShippingOptionField name={fieldName<ShippingInformationSave>("shippingMethod")} />
              <br />
              <Typography variant={"h3"} pb={2} pt={{ xs: 2, md: 0 }}>
                {values?.shippingMethod === ShippingOptions.PickupInShowroom
                  ? "Pick up in Showroom"
                  : "Shipping Information"}
              </Typography>
              <div className="inner-frame">
                <ShippingOptionComponent shippingInformation={shippingInformation} />
              </div>
              <br />
              <Typography variant={"h3"} pb={2} pt={{ xs: 2, md: 0 }}>
                Additional Information
              </Typography>
              <div className="inner-frame">
                <AdditionalInformationFields
                  fieldNames={fieldNames<AdditionalShippingInformation, ShippingInformationSave>({
                    secondaryEmails: "secondaryEmails",
                    orderComment: "orderComment",
                    customerPoNumber: "customerPoNumber",
                    saveSecondaryEmails: "saveSecondaryEmails",
                    emailNotificationValue: "emailNotificationValue",
                    rememberNotificationDetails: "rememberNotificationDetails",
                    shouldReceiveEmailNotifications: "shouldReceiveEmailNotifications",
                    shouldReceiveTextNotifications: "shouldReceiveTextNotifications",
                    textNotificationValue: "textNotificationValue",
                  })}
                />
              </div>
              <Box pt={4}>
                <MarathonButton
                  fullWidth
                  color={"secondary"}
                  loaderProps={{ sx: { color: "white" } }}
                  disabled={isLoading || cart?.isCheckoutDisabled}
                  isLoading={isLoading}
                  onClick={handleSubmit}
                >
                  <span>Continue</span>
                </MarathonButton>
              </Box>
            </>
          )}
        />
      )}
    </>
  );
};
