import React from "react";
import Confirmation from "./Confirmation";
import Payment from "./payment/components/Payment";
import { CheckoutOrderSummary } from "./CheckoutOrderSummary";
import { OrderConfirmed } from "./components/OrderConfirmed";
import { useCart } from "./hooks/useCart";
import { useConfirmOrder } from "./hooks/useConfirmOrder";
import { useCheckoutStep } from "./hooks/useCheckoutStep";
import { Grid } from "@mui/material";
import { Shipping } from "./shipping/Shipping";
import { CreditCardForm } from "./payment/components/CreditCardForm";

export enum CheckoutSteps {
  Shipping = "shipping",
  Payment = "payment",
  CreditCardEntry = "creditCardEntry",
  Confirmation = "confirmation",
  Confirmed = "confirmed",
}

type Props = {};

export const Checkout: React.FC<Props> = () => {
  const checkoutPath = window.location.pathname.split("/").pop() as CheckoutSteps;
  const { checkoutStep, setCheckoutStep } = useCheckoutStep(checkoutPath);
  const { cart, setCart, isCartLoading, setIsCartLoading } = useCart(checkoutStep, true);
  const { order, isOrderLoading, runConfirmOrder, tokenWithPrice, tokenNoPrice } = useConfirmOrder(
    {
      setCart,
      setIsCartLoading,
    },
    setCheckoutStep
  );

  const renderCheckoutStep = () => {
    switch (checkoutStep) {
      case CheckoutSteps.Shipping:
        return <Shipping setCheckoutStep={setCheckoutStep} cart={cart} />;
      case CheckoutSteps.Payment:
        return <Payment setCheckoutStep={setCheckoutStep} cart={cart} />;
      case CheckoutSteps.CreditCardEntry:
        return <CreditCardForm setCheckoutStep={setCheckoutStep} cart={cart} />;
      case CheckoutSteps.Confirmation:
        return (
          <Confirmation
            setCheckoutStep={setCheckoutStep}
            cart={cart}
            confirmOrder={runConfirmOrder}
            isAwaitingConfirmation={isOrderLoading}
          />
        );
      case CheckoutSteps.Confirmed:
        return (
          <OrderConfirmed
            order={order}
            isLoading={isOrderLoading}
            tokenWithPrice={tokenWithPrice}
            tokenNoPrice={tokenNoPrice}
          />
        );
    }
  };

  return (
    <main id="main">
      <section className="checkout checkout-details">
        <Grid container columnSpacing={2} px={4}>
          <Grid item xs={12} lg={6}>
            {renderCheckoutStep()}
          </Grid>
          <Grid item xs={12} lg={6} pt={{ xs: 4, md: 0 }}>
            <CheckoutOrderSummary checkoutStep={checkoutStep} cart={cart} isLoading={isCartLoading} />
          </Grid>
        </Grid>
      </section>
    </main>
  );
};

export default Checkout;
