import { Stack } from "@mui/material";
import React from "react";
import { MarathonButton, MarathonButtonProps } from "../../../common/components/MarathonButton";
import { useIsMobile } from "../../../common/hooks/useIsMobile";
import type { Cart } from "../../types/Cart";

export type CheckoutStepNavigationButtonsProps = {
  cart?: Cart | null;
  goBack: () => void;
  goBackText: React.ReactNode;
  backButtonProps?: Partial<MarathonButtonProps>;
  goForward: () => void;
  isForwardLoading?: boolean;
  goForwardText: React.ReactNode;
  forwardButtonProps?: Partial<MarathonButtonProps>;
  forwardButtonHidden?: boolean;
};

export const CheckoutStepNavigationButtons = ({
  cart,
  goForward,
  goBack,
  goForwardText,
  isForwardLoading,
  goBackText,
  forwardButtonProps,
  backButtonProps,
  forwardButtonHidden,
}: CheckoutStepNavigationButtonsProps) => {
  const isMobile = useIsMobile();
  return (
    <>
      <Stack direction={isMobile ? "column-reverse" : "row"} spacing={2} justifyContent={"space-between"}>
        <MarathonButton onClick={goBack} variant={"outlined"} color={"secondary"} {...backButtonProps}>
          {goBackText}
        </MarathonButton>
        {!forwardButtonHidden && (
          <MarathonButton
            disabled={isForwardLoading || cart?.isCheckoutDisabled}
            onClick={goForward}
            isLoading={isForwardLoading}
            color={"secondary"}
            loaderProps={{ sx: { color: "white" } }}
            {...forwardButtonProps}
          >
            {goForwardText}
          </MarathonButton>
        )}
      </Stack>
    </>
  );
};
