import { Box, Grid } from "@mui/material";
import { useAsyncData } from "@torqit/torq-tools-react";
import React, { useMemo, useState } from "react";
import { getUnconfirmedOrder } from "./api/getUnconfirmedOrder";
import { CheckoutSteps } from "./Checkout";
import { OrderDetails } from "./components/OrderDetails";
import { SpecialAndBackorderInfo } from "./components/SpecialAndBackorderInfo";
import { CheckoutStepNavigationButtons } from "./payment/components/CheckoutStepNavigationButtons";
import { Cart } from "./types/Cart";
import { ConfirmOrderParams } from "./types/ConfirmOrderParams";

type ConfirmationProps = {
  setCheckoutStep(checkoutStep: CheckoutSteps): void;
  confirmOrder: (params: ConfirmOrderParams) => Promise<void>;
  isAwaitingConfirmation: boolean;
  cart: Cart | null;
};

export const Confirmation = ({ setCheckoutStep, cart, confirmOrder, isAwaitingConfirmation }: ConfirmationProps) => {
  const [holdOrder, setHoldOrder] = useState(false);
  const { data: order, isLoading: isOrderLoading } = useAsyncData(getUnconfirmedOrder, []);

  const hasBackOrderedProducts = useMemo(() => (cart?.backOrderedProducts?.length ?? [].length) > 0, [cart]);
  const hasSpecialOrderedProducts = useMemo(() => (cart?.specialOrderProducts?.length ?? [].length) > 0, [cart]);

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <div>
          <h2>Order Confirmation</h2>
          <h3>Please confirm your order.</h3>
          <OrderDetails order={order} isLoading={isOrderLoading} />
        </div>
      </Grid>
      {(hasBackOrderedProducts || hasSpecialOrderedProducts) && (
        <Grid item xs={12}>
          <SpecialAndBackorderInfo
            hasBackOrderedProducts={hasBackOrderedProducts}
            hasSpecialOrderedProducts={hasSpecialOrderedProducts}
            holdOrder={holdOrder}
            setHoldOrder={setHoldOrder}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box pt={2}>
          <CheckoutStepNavigationButtons
            cart={cart}
            goBack={() => setCheckoutStep(CheckoutSteps.Payment)}
            goBackText={"Back to Billing"}
            goForward={() => confirmOrder({ backorderShip: holdOrder ? "Full" : "Partial" })}
            isForwardLoading={isAwaitingConfirmation}
            goForwardText={"Confirm and Pay"}
            forwardButtonProps={{ fullWidth: true }}
            backButtonProps={{ fullWidth: true }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Confirmation;
